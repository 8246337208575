/* eslint-disable no-param-reassign */
import * as ActionTypes from 'actions/ActionTypes'
import produce from 'immer'
import { findIndex, findLast, findLastIndex, isArray, isEmpty, partition, pullAll, sortBy } from 'lodash'
import { getRippleDistance } from '~/helpers/getRippleDistance'
import { parseLoadedFile } from '~/Util/sourceFile'
import Asset, { AudioAsset, ImageAsset, PlaceholderAsset, TextAsset, TransitionAsset, VideoAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import * as Transitions from './transitions'
import { ASSET_SIZE_STATUS } from '~/config/constants/asset'
import { patchAssetData } from '~/helpers/assets/patchAssetData'
import { getTransitionStartTime } from '~/helpers/actions/getTransitionStartTime'
import { TRANSITIONS } from '~/enums'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'
import { generateClientId } from '~/models/Asset/Asset'
import { isAvailableAttachTransition } from '~/helpers/assets/isAvailableAttachTransition'

const TRANSITION_MIN_DURATION = 2500000

/**
 * @typedef {object} LayerState
 * @property {InstanceType<typeof Asset>[]} assets
 *
 */

/**
 * @type LayerState
 */
const initialState = {
  assets: [],
  assetsSizeStatus: ASSET_SIZE_STATUS.RESET,
  copiedAssets: [],
  dndPatchAssets: {},
}

const layer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.LOAD_PROJECT: {
      const { assets } = action.payload
      state.assets = assets.map(a => parseLoadedFile(a.sourceFileType, a))
      break
    }

    case ActionTypes.TEMP_ASSET_ADDED_TO_TIMELINE: {
      addAsset(state, action.payload)
      break
    }

    case ActionTypes.ASSET_MOVED_ON_TIMELINE: {
      const { asset, startTime, layerId } = action.payload
      const assetToUpdate = state.assets.find(x => x.id === asset.id)
      moveAsset(state, assetToUpdate, { startTime, layerId })
      break
    }

    case ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE: {
      const { dragAssetIds,
        transitionsToMove, shiftTime, dragAssetsByLayerIndex,
        layersShiftTime, layerIds } = action.payload

      onMovedMultipleAssets({
        state,
        dragAssetIds,
        transitionsToMove,
        shiftTime,
        dragAssetsByLayerIndex,
        layersShiftTime,
        layerIds,
      })
      break
    }

    case ActionTypes.UNDO_MULTIPLE_MOVE_ASSETS: {
      const { serviceData, startTime, deltaOnDrop } = action.payload
      undoMultipleMoveAssets(state, serviceData, deltaOnDrop, startTime)
      break
    }

    case ActionTypes.EMPTY_LAYER_AREA_SELECTED: {
      selectEmptyArea(state, action.payload)
      break
    }

    case ActionTypes.TIMELINE_CLEARED:
      state.assets = []
      break

    case ActionTypes.RIPPLE_TRIM: {
      const { ripple, sliderTime, rippleTime, layers, pregeneratedIds } = action.payload
      rippleTrim(state, ripple, sliderTime, rippleTime, layers, pregeneratedIds)
      break
    }

    case ActionTypes.UNDO_RIPPLE_TRIM: {
      const { patchs, deleteAssetIds } = action.payload
      undoRippleTrim(patchs, deleteAssetIds, state)
      break
    }

    case ActionTypes.SPLIT: {
      const { asset, sliderTime, pregeneratedId } = action.payload
      split(state, { asset, startSplitTime: sliderTime, pregeneratedId, selected: true })
      break
    }

    case ActionTypes.MULTIPLE_SPLIT: {
      const { assets, sliderTime } = action.payload
      splitMultipleAssets(state, assets, sliderTime)
      break
    }

    case ActionTypes.UNDO_MULTIPLE_SPLIT: {
      const { assets, assetIDsBeforeSplitting,
        transitionsBeforeSplitting, sliderTime } = action.payload
      undoSplitMultipleAssets(state, assets, assetIDsBeforeSplitting,
        transitionsBeforeSplitting, sliderTime)
      break
    }

    case ActionTypes.ASSET_SETTINGS_FORM_UPDATED:
    case ActionTypes.ASSET_PREVIEW_UPDATED: {
      const { id, patch } = action.payload
      patchAsset(id, patch, state)
      break
    }

    case ActionTypes.ASSET_SELECTED: {
      const { id, modifiers } = action.payload
      handleAssetClick(state, id, modifiers)
      break
    }

    case ActionTypes.TOGGLE_SELECTED_ASSETS: {
      const { assetsIds } = action.payload
      toggleSelectAssetsById(state, assetsIds)
      break
    }

    case ActionTypes.SELECT_CLIPS_AT_CURRENT_POSITION: {
      const { sliderPosition } = action.payload
      onSelectClipsAtCurrentPosition(state, sliderPosition)
      break
    }

    case ActionTypes.ASSET_ADDED_TO_TIMELINE: {
      const { asset } = action.payload
      addAsset(state, asset)
      break
    }
    case ActionTypes.COPIED_ASSET_ADDED_TO_TIMELINE: {
      const { assets, transitions } = action.payload
      insertCopiedAssets(state, assets, transitions)
      const copiedAssetsIds = assets.map(asset => asset.id)
      toggleSelectAssetsById(state, copiedAssetsIds)
      onAssetsCopy(state, assets)
      break
    }
    case ActionTypes.DELETE_ASSETS:
      deleteAssets(action.payload, state)
      onAssetsCopy(state, action.payload)
      break
    case ActionTypes.RIPPLE_DELETE_ASSETS:
      deleteAssets(action.payload, state, true)
      break
    case ActionTypes.CLEAR_ASSETS_SELECTION:
      clearSelection(state, action.payload)
      break
    case ActionTypes.ASSET_CHANGE_DURATION: {
      const { duration, id } = action.payload
      const asset = state.assets.find(a => a.id === id)
      if (asset) {
        asset.duration = duration
      }
      break
    }

    case ActionTypes.DELETE_TEMP_ASSETS: {
      pullAll(state.assets, state.assets
        .filter(asset => action.payload.some(id => id === asset.id)))
      break
    }

    case ActionTypes.SHIFT_ITEMS_BY_ASSET: {
      const { asset, delta } = action.payload
      shiftItemsByAsset(state, asset.id, delta)
      break
    }
    case ActionTypes.SHIFT_ITEMS_BY_TIME: {
      shiftItemsByTime(state, action.payload)
      break
    }
    case ActionTypes.DELETE_TRANSITIONS: {
      const { transitions } = action.payload
      Transitions.deleteTransitions(state, transitions)
      break
    }
    case ActionTypes.SET_DND_PATCH_ASSETS: {
      const { assetIds } = action.payload
      state.dndPatchAssets = state.assets
        .filter(asset => assetIds.includes(asset.id))
        .reduce((result, asset) => {
          result[asset.id] = {
            id: asset.id,
            layerId: asset.layerId,
            duration: asset.duration,
            startTime: asset.startTime,
            originDuration: asset.originDuration,
          }
          return result
        }, {})
      break
    }
    case ActionTypes.RESET_DND_PATCH_ASSETS: {
      state.dndPatchAssets = {}
      break
    }
    case ActionTypes.DELETE_PLACEHOLDERS: {
      const { placeholders, options = {} } = action.payload
      deletePlaceholders(state, placeholders, options)
      break
    }
    case ActionTypes.ASSETS_COPY: {
      const { assets } = action.payload
      onAssetsCopy(state, assets)
      break
    }
    case ActionTypes.TRANSITION_ATTACHED_TO_ASSET:
      Transitions.attachTransitionToVideo(state, action.payload)
      break
    case ActionTypes.TRANSITION_ADDED_TO_TIMELINE: {
      const { asset } = action.payload
      addAsset(state, asset)
      break
    }
    case ActionTypes.TRANSITION_REATTATCH: {
      const { transitionId, leftAssetId, rightAssetId } = action.payload
      const transitionAsset = state.assets.find(asset => asset.id === transitionId)
      const rightAsset = state.assets.find(asset => asset.id === rightAssetId)
      const leftAsset = state.assets.find(asset => asset.id === leftAssetId)

      let additionAllowed = false
      switch (transitionAsset?.type) {
        case TRANSITIONS.DISSOLVE:
          additionAllowed = isAvailableAttachTransition(leftAsset)
            && isAvailableAttachTransition(rightAsset)
          break
        case TRANSITIONS.FADEIN:
          additionAllowed = isAvailableAttachTransition(rightAsset)
          break
        case TRANSITIONS.FADEOUT:
          additionAllowed = isAvailableAttachTransition(leftAsset)
          break
        default:
          break
      }

      if (additionAllowed) {
        Transitions.adjustVideoAssetsByTransition(state, transitionAsset, true)
        Transitions.attachTransitionToVideo(state, { transitionId,
          leftAssetId,
          rightAssetId })
        const rightAsset = state.assets.find(asset => asset.id === rightAssetId)
        const leftAsset = state.assets.find(asset => asset.id === leftAssetId)
        if (transitionAsset) {
          const transitionStartTime = getTransitionStartTime(transitionAsset, leftAsset, rightAsset)
          transitionAsset.startTime = transitionStartTime
        }
      }
      break
    }
    case ActionTypes.TRANSITION_SET_START_TIME: {
      const { startTime, transitionId } = action.payload
      const transition = state.assets.find(asset => asset.id === transitionId)
      if (transition) {
        transition.startTime = startTime
      }
      break
    }
    case ActionTypes.SOURCE_FILE_STATUS_UPDATED: {
      const { id, progress, status } = action.payload.data
      const assetsWithSourceUpdatedFile = state.assets.filter(a => a.fileId === id)
      assetsWithSourceUpdatedFile.forEach(a => patchAsset(a.id, { status, progress }, state))
      break
    }

    case ActionTypes.LAYER_ASSETS_REHYDRATE: {
      const { layers: activeProjectLayers } = action.payload
      if (activeProjectLayers && state.assets?.length) {
        const activeProjectAssets = activeProjectLayers.reduce((result, layer) => {
          if (layer.assets?.length) {
            result = [ ...result, ...layer.assets ]
          }
          return result
        }, [])

        if (activeProjectAssets.length) {
          state.assets = state.assets.map(asset => {
            const foundActiveProjectAsset = activeProjectAssets
              .find(activeProjectAsset => activeProjectAsset.id === asset.id)
            if (foundActiveProjectAsset) {
              asset.settings = foundActiveProjectAsset.settings
            }
            return asset
          })
        }
      }
    } break
    case ActionTypes.UNDO_CREATE_TRANSITION: {
      const { undoTransition, newAssets } = action.payload
      const currentLayerId = newAssets?.[0]?.layerId

      state.assetsSizeStatus = 0
      state.assets = state.assets.filter(asset => asset.id !== undoTransition.id).map(asset => {
        const find = newAssets.find(newAsset => newAsset.id === asset.id)
        return find ?? asset
      }).sort((a, b) => a.startTime - b.startTime)

      /*  Move connected assets and transitions to undoTransition if exists */
      let rightConnectedDissolveAsset = state.assets
        .find(asset => asset.id === undoTransition.rightVideoAssetId
          && (undoTransition.type === TRANSITIONS.DISSOLVE))

      if (rightConnectedDissolveAsset) {
        const restTransitions = state.assets
          .filter(asset => (asset instanceof TransitionAsset)
        && (asset.id !== undoTransition.id) && (currentLayerId === asset.layerId))
          .sort((a, b) => a.startTime - b.startTime)

        restTransitions.forEach(restDissolve => {
          const leftRestDissolveAsset = state.assets.find(el => restDissolve.isAttachedTo(el.id, 'left'))
          const rightRestDissolveAsset = state.assets.find(el => restDissolve.isAttachedTo(el.id, 'right'))
          if (leftRestDissolveAsset?.id === rightConnectedDissolveAsset.id) {
            restDissolve.startTime = rightConnectedDissolveAsset.endTime - restDissolve.duration
            if (rightRestDissolveAsset && (restDissolve.type === TRANSITIONS.DISSOLVE)) {
              rightRestDissolveAsset.startTime += restDissolve.duration
              rightConnectedDissolveAsset = rightRestDissolveAsset
            }
          }
        })
      }
      /* \ Move connected assets and transitions to undoTransition if exists */
    } break
    case ActionTypes.UNDO_DELETE_TRANSITION: {
      const { transition, restoredAssets } = action.payload
      state.assetsSizeStatus = 0
      const currentTransitions = [].concat(transition)
      currentTransitions.forEach(singleTransition => state.assets.push(singleTransition))
      restoredAssets.forEach(restoredAsset => {
        const existAsset = state.assets.find(asset => asset.id === restoredAsset.id)
        if (!existAsset) {
          state.assets.push(restoredAsset)
        } else {
          state.assets = state.assets.map(asset => {
            if (asset.id === restoredAsset.id) {
              return restoredAsset
            }
            return asset
          })
        }
      })
      state.assets = state.assets.sort((a, b) => a.startTime - b.startTime)
    } break
  }
}

export default produce(layer, initialState)

// ---

function rippleNextAssets(state, startTime, duration) {
  const distance = getRippleDistance(state.assets, startTime, duration)
  const rippleAssets = state.assets.filter(a => a.startTime > startTime)
    .sort((a, b) => a.startTime - b.startTime)
  rippleAssets.forEach(a => {
    a.startTime -= distance
  })
}

function deleteAssets(payload, state, ripple = false) {
  if (ripple) {
    const sortedAssets = payload.assets
      .concat(payload.placeholders)
      .sort((a, b) => b.startTime - a.startTime)
    sortedAssets.forEach(asset => {
      const { startTime, duration } = asset
      state.assets = state.assets.filter(a => a.id !== asset.id)
      rippleNextAssets(state, startTime, duration)
    })
  } else {
    const { assets, transitions } = payload
    if (assets && !isEmpty(assets)) {
      state.assets = state.assets
        .filter(stateAsset => !assets.find(asset => asset.id === stateAsset.id))
    }
    if (transitions && !isEmpty(transitions)) {
      state.assets = state.assets
        .filter(stateAsset => !transitions.find(transition => transition.id === stateAsset.id))
    }
  }
}

function patchAsset(id, updatePatch, state) {
  const patch = { ...updatePatch }
  const asset = state.assets.find(x => x.id === id)
  if (asset) {
    if (asset instanceof TransitionAsset) {
      if (patch.duration) {
        Transitions.onTransitionDurationChange(state, asset, patch.duration, patch?.applyToAll)
      }
    }

    const assets = Asset.getLayerAssets(state.assets, asset.layerId)
      .sort((a, b) => a.startTime - b.startTime)

    if (assets.length > 1 && (patch.duration !== undefined || patch.startTime !== undefined)) {
      if (patch.duration !== undefined) {
        const rightIntersectsAsset = assets.filter(a => a.id !== asset.id)
          .find(a => a.startTime < asset.startTime + patch.duration
            && a.startTime > asset.startTime && a.id !== asset.id)

        if (rightIntersectsAsset && !(rightIntersectsAsset instanceof TransitionAsset)) {
          patch.duration = rightIntersectsAsset.startTime - asset.startTime
        }
      }

      if (patch.startTime !== undefined) {
        const leftIntersectsAsset = assets.filter(a => a.id !== asset.id)
          .reverse()
          .find(a => a.endTime > patch.startTime
            && a.startTime < asset.startTime)

        if (leftIntersectsAsset && !(leftIntersectsAsset instanceof TransitionAsset)) {
          const transitions = state.assets.filter(item => (item.id !== leftIntersectsAsset.id
            && item.id !== id && item instanceof TransitionAsset
            && item.isAttachedTo(leftIntersectsAsset.id, 'left') && item.isAttachedTo(id, 'right')))

          if (!transitions.length) {
            patch.startTime = leftIntersectsAsset.endTime
          }
        }
      }
    }

    Object.assign(asset, patch)
  }
}

function addAsset(state, asset) {
  state.assets.push(asset)
  // timeline will have unsorted assets when its moved on a layer
  // or between layers, but when we add new asset, it will garantuee
  // that it will be sorted. It is especially important for transitions.
  state.assets = sortBy(state.assets, x => x.startTime)
}

function insertCopiedAssets(state, newAssets, transitions = []) {
  state.assets = sortBy(state.assets.concat(newAssets, transitions), x => x.startTime)
}

function moveAsset(state, asset, { startTime, layerId }) {
  if (asset.canHaveTransition) {
    Transitions.onVideoAssetMoved(state, asset.id, asset.startTime, startTime, layerId)
  }

  // console.log(state.assets.map(a => ({ ...a })))
  // console.log(asset.id)

  Object.assign(asset, { startTime, layerId })
}

// eslint-disable-next-line no-unused-vars
function restoreAssetFromBackup(state, backup) {
  const { assets } = state
  const index = assets.findIndex(x => x.id === backup.id)
  const asset = assets[index]

  // While editing, asset can also be moved on timeline, or splitted.
  // These changes should not be reverted when restoring backup.
  assets.splice(index, 1, backup.clone({
    startTime: asset.startTime,
    duration: asset.duration,
    mediaStart: asset.mediaStart,
  }, { keepSourceId: true }))
}

function handleAssetClick(state, assetId, modifiers = {}) {
  const clickedAsset = state.assets.find(x => x.id === assetId)
  const [ transitions, noTransitions ] = partition(state.assets, x => x instanceof TransitionAsset)

  const { ctrlKey = false, shiftKey = false } = modifiers
  const shouldToggleSelection = ctrlKey === true
  const shouldExtendSelection = shiftKey === true
  // ---

  if (clickedAsset instanceof PlaceholderAsset) {
    if (shouldToggleSelection) {
      // PlaceholderAsset is a selected empty area. De-selecting placeholder means removing it.
      // Other placeholders shouldn't be affected – same as it is with normal assets.
      pullAll(state.assets, [ clickedAsset ])
    } else {
      // If placeholder was *just* clicked (no modifiers), it should remain as it is,
      // and all other placeholders should be de-selected –
      // again, same as it is with normal assets.
      const allPlaceholders = PlaceholderAsset.filter(state.assets)
      if (allPlaceholders.length > 1) {
        state.assets = pullAll(state.assets, allPlaceholders).concat(clickedAsset)
      }
    }

    return
  }

  // ---
  // Whatever happens further, it will make some assets selected.
  // Which means, no selected empty areas should remain.
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.rb47k4388ezy
  state.assets = PlaceholderAsset.reject(state.assets)

  // ---
  // With ctrl pressed, clicked asset should be added to existing selection (regardless to layer).
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.vxmaod4wkgrb
  // And respectively, it should allow to exclude clicked asset from selection, because it's logical.
  if (shouldToggleSelection) {
    clickedAsset.selected = !clickedAsset.selected
    if (!(clickedAsset instanceof TransitionAsset)) {
      transitions.forEach(asset => {
        asset.selected = false
      })
    } else {
      noTransitions.forEach(asset => {
        asset.selected = false
      })
    }
    return
  }

  // ---

  // eslint-disable-next-line no-param-reassign
  const setSelected = selected => asset => { asset.selected = selected }
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.f89tz3yhfa99
  if (shouldExtendSelection) {
    const isTransition = clickedAsset instanceof TransitionAsset
    if (clickedAsset.selected && isTransition) {
      clickedAsset.selected = !clickedAsset.selected
      return
    }
    const [ layerAssets, otherAssets ] = partition(
      state.assets,
      x => x.layerId === clickedAsset.layerId
      && (isTransition ? x instanceof TransitionAsset : !(x instanceof TransitionAsset))
    )

    // Extending means interaction only with clicked layer.
    // Everything else should be deselected.
    otherAssets.forEach(setSelected(false))

    // ---

    const sorted = sortBy(layerAssets, asset => asset.startTime)
    const clickedAssetIndex = sorted.indexOf(clickedAsset)
    const normalizeIndex = x => x !== -1 ? x : clickedAssetIndex

    const closestRightSelectedIndex = findIndex(
      sorted, x => x.selected,
      clickedAssetIndex + 1
    )
    const closestLeftSelectedIndex = findLastIndex(
      sorted, x => x.selected,
      Math.max(0, clickedAssetIndex - 1)
    )

    sorted
      .slice(
        normalizeIndex(closestLeftSelectedIndex),
        normalizeIndex(closestRightSelectedIndex)
      )
      .forEach(setSelected(true))
  } else {
    state.assets.forEach(setSelected(false))
  }

  // ---
  // Unless explicit toggling selection with ctrl,
  // clicked asset should always be selected, no matter what.
  // @link http://18.184.210.86/issues/297#note-1
  clickedAsset.selected = true
}

function selectEmptyArea(state, { time, layerId }) {
  const assets = sortBy(
    Asset.getLayerAssets(state.assets, layerId),
    x => x.startTime
  )
  if (assets.some(x => time > x.startTime && time < x.endTime)) {
    return
  }

  const rightAsset = assets.find(x => x.startTime >= time)
  // area must be closed at right side to be selectable
  if (rightAsset === undefined) {
    return
  }

  const leftAsset = findLast(assets, x => x.startTime < time)
  // area between asset and layer start can be selected too
  const areaStartTime = leftAsset === undefined ? 0 : leftAsset.endTime

  state.assets.push(new PlaceholderAsset({
    layerId,
    duration: rightAsset.startTime - areaStartTime,
    startTime: areaStartTime,
  }))
}

function rippleTrim(state, ripple, sliderTime, rippleTime, layers, pregeneratedIds) {
  const assets = state.assets.filter(a => !(a instanceof PlaceholderAsset
    || a instanceof TransitionAsset))
  const startTime = ripple === 'next' ? sliderTime : rippleTime
  const endTime = ripple === 'next' ? rippleTime : sliderTime
  const trimDuration = endTime - startTime
  layers.forEach(layer => {
    const itemsToSplit = assets
      .filter(a => a.layerId === layer.id && ((a.startTime <= startTime && a.endTime >= endTime)
        || (a.startTime < endTime && a.endTime > startTime)))
      .map(asset => ({
        asset,
        start: asset.startTime > startTime ? asset.startTime : startTime,
        end: asset.endTime < endTime ? asset.endTime : endTime,
      }))
    itemsToSplit.forEach(({ asset, start, end }) => {
      split(state, {
        asset,
        startSplitTime: start,
        endSplitTime: end,
        pregeneratedId: pregeneratedIds?.[asset.id],
      })
      resizeOrDeleteAssetTransitions(state, ripple, asset)
    })
  })
  const movedAssets = state.assets.filter(a => a.startTime > startTime)
  movedAssets.sort((a, b) => a.startTime - b.startTime)
    .forEach(a => {
      a.startTime -= trimDuration
    })
}

function undoRippleTrim(patchs, deleteAssetIds, state) {
  state.assets = state.assets.filter(a => !deleteAssetIds.includes(a.id))
  const patchedAssets = state.assets
    .filter(a => Object.keys(patchs).includes(a.id))
    .sort((a, b) => b.startTime - a.startTime)
  patchedAssets.forEach(asset => Object.assign(asset, patchs[asset.id]))
}


function resizeTransition(state, transition, duration) {
  const delta = transition.duration - duration
  if (transition.rightVideoAssetId) {
    const rightAsset = state.assets.find(a => transition.isAttachedTo(a.id, 'right'))
    transition.startTime += delta
    rightAsset.startTime += delta
  }
  transition.duration = duration
}

function deleteTransition(state, transition) {
  state.assets = state.assets.filter(a => a.id !== transition.id)
  if (transition.isTwoSideTransition) {
    const leftAsset = state.assets.find(a => transition.isAttachedTo(a.id, 'left'))
    const rightAsset = state.assets.find(a => transition.isAttachedTo(a.id, 'right'))
    leftAsset.duration -= transition.duration / 2
    rightAsset.duration -= transition.duration / 2
    rightAsset.startTime += transition.duration / 2
  }
}

function split(state, { asset, startSplitTime, endSplitTime, pregeneratedId, selected }) {
  if (state.assets.length !== 0) {
    // find item on layer under cursor
    const itemToSplit = state.assets.find(a => a.id === asset.id)
    if (itemToSplit) {
      // create new item which is actually right part of the splitting item
      const {
        endTime,
        startTime,
        mediaStart,
      } = itemToSplit

      const transitions = TransitionAsset.filter(state.assets)
      const endSplit = endSplitTime || endTime
      const endCutTime = endSplitTime || startSplitTime
      const isEqualStart = Math.floor(startTime / 10000) === Math.floor(startSplitTime / 10000)
      const isEqualEnd = Math.floor(endTime / 10000) === Math.floor(endSplitTime / 10000)

      itemToSplit.duration -= endSplit - startSplitTime

      if (isEqualStart && isEqualEnd) {
        const deletedAssets = transitions.filter(t => t.isAttachedTo(itemToSplit.id))
          .concat([ itemToSplit ])
        pullAll(state.assets, deletedAssets)
        const movedAssets = state.assets.filter(a => a.layerId === itemToSplit.layerId
          && !(a instanceof PlaceholderAsset))
        movedAssets.forEach(a => {
          a.startTime -= startSplitTime - startTime
        })
      } else if (startTime < startSplitTime && endSplit <= endTime) {
        const duration = endTime - endCutTime
        const splitTime = endCutTime - startTime + mediaStart
        const patch = {
          duration,
          startTime: startSplitTime,
          mediaStart: splitTime,
          selected: selected || false,
        }

        if (duration > 0) {
          if (itemToSplit instanceof TextAsset || itemToSplit instanceof ImageAsset) {
            // const clonedAsset = itemToSplit.clone(patch, { pregeneratedId })
            const clonedAsset = patchAssetData(itemToSplit,
              patch, { pregeneratedId, createNewAsset: true })
            state.assets.push(clonedAsset)
          } else if (itemToSplit instanceof VideoAsset || itemToSplit instanceof AudioAsset) {
            // const newAsset = itemToSplit.clone(patch, { pregeneratedId })
            const newAsset = patchAssetData(itemToSplit,
              patch, { pregeneratedId, createNewAsset: true })
            newAsset.clearThumbnail()

            state.assets.push(newAsset)

            const transition = transitions.find(tr => tr.isAttachedTo(itemToSplit.id, 'left'))
            if (transition !== undefined) {
              if (newAsset.duration < transition.duration) {
                resizeTransition(state, transition, newAsset.duration)
              }

              transition.attachTo(newAsset.id, 'left')
            }
          }
        }

        itemToSplit.duration = startSplitTime - startTime
      } else if (endTime > endSplitTime) {
        itemToSplit.mediaStart = endSplitTime - startTime + itemToSplit.mediaStart
      }
    }
  }
}

function splitMultipleAssets(state, selectedAssets, sliderTime) {
  const transitions = TransitionAsset.filter(state.assets)
  const iDsForDeletion = []
  const newAssetsList = []
  selectedAssets.forEach(asset => {
    const transition = transitions.find(tr => tr.isAttachedTo(asset.id, 'left'))
    const newAsset = cloneInstanceClass(asset)
    newAsset.id = generateClientId()
    newAsset.startTime = sliderTime
    newAsset.mediaStart = sliderTime
    newAsset.endTime = asset.endTime
    newAsset.duration = asset.endTime - sliderTime

    if (transition) {
      transition.attachTo(newAsset.id, 'left')
      iDsForDeletion.push(transition.id)
    }

    iDsForDeletion.push(asset.id)
    const existingAsset = cloneInstanceClass(asset)
    existingAsset.duration = sliderTime - asset.startTime
    existingAsset.endTime = sliderTime

    if (transition) {
      newAssetsList.push(transition)
    }
    newAssetsList.push(newAsset)
    newAssetsList.push(existingAsset)
  })

  state.assets = state.assets.filter(asset => !iDsForDeletion
    .includes(asset.id))

  newAssetsList.forEach(asset => {
    state.assets.push(asset)
  })
  state.assets.sort((a, b) => b.startTime - a.startTime)
}

function undoSplitMultipleAssets(state, undoAssets, assetIDsBeforeSplitting,
  transitionsBeforeSplitting) {
  state.assets = state.assets.filter(asset => assetIDsBeforeSplitting.includes(asset.id))
  state.assets = state.assets.map(layerAsset => {
    const foundAsset = undoAssets.find(asset => asset.id === layerAsset.id)
    if (foundAsset) {
      return foundAsset
    }
    if (transitionsBeforeSplitting[layerAsset.id]) {
      const clone = cloneInstanceClass(layerAsset)
      clone.leftVideoAssetId = transitionsBeforeSplitting[layerAsset.id].leftVideoAssetId
      clone.rightVideoAssetId = transitionsBeforeSplitting[layerAsset.id].rightVideoAssetId
      return clone
    }
    return layerAsset
  })
}

function resizeOrDeleteAssetTransitions(state, ripple, asset) {
  const transitions = TransitionAsset.filter(state.assets)
    .filter(t => t.isAttachedTo(asset.id))
    .sort((a, b) => ripple === 'next' ? b.startTime - a.startTime : a.startTime - b.startTime)
  const transitionsDuration = transitions.reduce((sum, t) => sum + t.duration, 0)
  if (asset.duration < transitionsDuration) {
    const transition = transitions[0]
    const newDuration = asset.duration - (transitionsDuration - transition.duration)
    if (newDuration >= TRANSITION_MIN_DURATION) {
      resizeTransition(state, transition, newDuration)
    } else {
      deleteTransition(state, transition)
      resizeOrDeleteAssetTransitions(state, ripple, asset)
    }
  }
}

function shiftItemsByAsset(state, assetId, delta) {
  if (!assetId) {
    return
  }

  const asset = state.assets.find(a => a.id === assetId)

  const rightSideItems = Selectors.getRightSideAssetsExceptFadeoutTransitions(state, asset)

  rightSideItems.forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.startTime += delta
  })
}

function shiftItemsByTime(state, { layerId, time, delta, ignoredAssetsId }) {
  let rightSideItems = Selectors.getRightSideAssetsByTimeAndLayer(state, layerId, time)
  rightSideItems = rightSideItems.filter(item => !ignoredAssetsId.includes(item.id))
  rightSideItems.forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.startTime += delta
  })
}

function updateAssetsSelection(state, { ids, selected }) {
  const assetsIds = [].concat(ids)

  const changed = state.assets.filter(asset => assetsIds.includes(asset.id))
  const [ placeholders, others ] = partition(changed, x => x instanceof PlaceholderAsset)
  if (selected === false) {
    pullAll(state.assets, placeholders)
  }

  others.forEach(asset => {
    // eslint-disable-next-line no-param-reassign
    asset.selected = selected
  })
}

function deletePlaceholders(state, placeholders, { shiftItems = true } = {}) {
  if (placeholders === undefined || placeholders.length === 0) {
    return
  }

  // iterate placeholder assets
  if (shiftItems) {
    placeholders.forEach(placeholder => {
      state.assets.forEach(asset => {
        if (asset.startTime > placeholder.startTime && asset.layerId === placeholder.layerId) {
          // eslint-disable-next-line no-param-reassign
          asset.startTime -= placeholder.duration
        }
      })
    })
  }

  state.assets = PlaceholderAsset.reject(state.assets)
}

function clearSelection(state, { options }) {
  const {
    all: clearAll = true,
    assets: clearAssets = false,
    placeholders: clearPlaceholders = false,
  } = options

  const ids = state.assets
    .filter(asset => asset.selected)
    .filter(asset => (
      clearAll
      || (clearAssets && !(asset instanceof PlaceholderAsset))
      || (clearPlaceholders && asset instanceof PlaceholderAsset)
    ))
    .map(asset => asset.id)

  updateAssetsSelection(state, { ids, selected: false })
}

function toggleSelectAssetsById(state, assetsIds) {
  state.assets = state.assets.filter(asset => {
    if (!(asset instanceof PlaceholderAsset)) {
      if (asset instanceof TransitionAsset) {
        const { leftVideoAssetId, rightVideoAssetId } = asset
        if (assetsIds?.includes(leftVideoAssetId || rightVideoAssetId)) {
          asset.selected = false
        }
      } else if (assetsIds?.includes(asset.id)) {
        asset.selected = true
      } else {
        asset.selected = false
      }
      return true
    }
    return false
  })
}

function onSelectClipsAtCurrentPosition(state, sliderPosition) {
  state.assets = state.assets.filter(asset => {
    if (!(asset instanceof PlaceholderAsset)) {
      if (!(asset instanceof TransitionAsset)
      && asset.startTime <= sliderPosition && sliderPosition <= asset.endTime) {
        asset.selected = true
      } else {
        asset.selected = false
      }
      return true
    }
    return false
  })
}

function onAssetsCopy(state, payload) {
  let copiedAssetsPayload = payload
  if (!isArray(payload)) {
    const { assets, transitions } = payload
    copiedAssetsPayload = [ ...(assets ?? []), ...(transitions ?? []) ]
  }
  state.copiedAssets = copiedAssetsPayload
}

function onMovedMultipleAssets({
  state,
  dragAssetIds,
  transitionsToMove,
  shiftTime,
  dragAssetsByLayerIndex,
  layersShiftTime,
  layerIds,
}) {
  const transitions = TransitionAsset.filter(state.assets)
  state.assets.forEach(asset => {
    if (!(asset instanceof PlaceholderAsset)
    && (dragAssetIds.includes(asset.id) || transitionsToMove.includes(asset.id))) {
      asset.startTime += shiftTime
    }

    const layerIndex = layerIds.indexOf(asset.layerId)

    // Static assets that need to be moved
    const { shiftStaticTime, shiftStaticAssetIds } = layersShiftTime[layerIndex] ?? {}

    if (shiftStaticTime && !dragAssetIds.includes(asset.id)
      && shiftStaticAssetIds.includes(asset.id)) {
      asset.startTime += shiftStaticTime
    }

    Object.values(dragAssetsByLayerIndex).forEach(dragAssets => {
      dragAssets.forEach(dragAsset => {
        if (dragAsset.id === asset.id && dragAsset.newLayerId) {
          asset.layerId = dragAsset.newLayerId
        }
        // Get all move asset transions
        const dragTransitions = transitions.filter(trans => trans.isAttachedTo(dragAsset.id)
          && transitionsToMove.includes(trans.id))

        if (!isEmpty(dragTransitions)) {
          dragTransitions.forEach(trans => {
            trans.layerId = dragAsset.newLayerId
          })
        }
      })
    })
  })
  state.dndPatchAssets = {}
}

function undoMultipleMoveAssets(state, serviceData, deltaOnDrop, startTime) {
  state.assets.forEach(stateAsset => {
    serviceData.assets.forEach(dragAsset => {
      if (stateAsset.id === dragAsset.id) {
        stateAsset.layerId = dragAsset.layerId
        stateAsset.startTime = dragAsset.startTime
      }
    })
  })
  if (deltaOnDrop) {
    shiftItemsByTime(state, {
      layerId: serviceData.insertBetweenLayerID,
      time: startTime,
      delta: deltaOnDrop * -1,
      ignoredAssetsId: serviceData.selectedDragIds,
    })
  }
}
